import React from 'react';
import abouthome from '../img/aboutimg.jpg'
const AboutCompany = () => {
  return (
    <section className="no-top no-bottom bggray" aria-label="abouthome">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 p-0">
        <img src={abouthome}className="imgbg-col" alt="imghome" />
          </div>
          <div className="col-md-6 centered">
            <div className="detailcontent">
              <div className="subheading" style={{ color: '#1c1d1d', fontSize: '48px', textAlign: 'center', marginBottom: '11px' }}>
                About <span style={{ color: '#1c1d1d', fontSize: '48px' }}>Company</span>
              </div>
              {/* <div className="heading">Best Interior is Our Passion</div> */}
              <div className="textdetail">
              <p style={{ fontSize: '16px', textAlign: 'justify' }}>
              <span style={{fontFamily: 'Raqupine Regular' }}> AA APPLICATIONS </span> provides organizations of all sizes with the best, most trustworthy IT solutions. In the ever-evolving technology market, we believe that companies need reliable allies who can guide them through the challenges that accompany technological growth. We provide IT Support, Services and development services - to name a few. Contact us today to find out more.
               </p>
               <p style={{ fontSize: '16px', textAlign: 'justify' }}>
               "Providing Automated Products for  Enterprises"
               In order for enterprises to be fully compliant with regulations, enterprises need to improve business processes, information flow within and across enterprises and provide secure information delivery. </p>
               <p style={{ fontSize: '16px', textAlign: 'justify' }}>
               <span style={{fontFamily: 'Raqupine Regular' }}> AA APPLICATIONS </span> provides a customer-focused products specialized in business solutions using advanced technologies.  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutCompany;
