import React, { useEffect } from 'react';
import Services from './Services'
import Aboutus from './Aboutus'
import MainBanner from './MainBanner'
import Stack from './Stack'
import Whychoose from './Whychoose'
// import Gallery from './Gallery'
import ContactForm from './ContactForm';

const Home = () => {
     // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures it only runs once when the component mounts

  return (
    <div>
        <MainBanner/>  
        <Stack/>
        <Services/>
        <Aboutus/>
        <Whychoose/>
        <ContactForm/>
        {/* <Gallery/> */}
    </div>
  )
}

export default Home