import React, { useEffect } from 'react'
import ContactBanner from './ContactBanner'
import ContactForm from './ContactForm'
// import ContactMap from './ContactMap'

const Contactus = () => {
       // Scroll to the top of the page when the component mounts
       useEffect(() => {
        window.scrollTo(0, 0);
      }, []); // Empty dependency array ensures it only runs once when the component mounts
    
  return (
    <div>
        <ContactBanner/>
        <ContactForm/>
        {/* <ContactMap/> */}
    </div>
  )
}

export default Contactus