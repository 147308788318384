import React from 'react';
import bannerImage from '../img/banner/banner2.jpeg'; // Adjust the path according to your project structure

const WhoweareBanner = () => {
  return (
    <div className="hero-area overly-style-1 opacity-point-4">
      {/* Replace video with image */}
      <img 
        className="banner-image" 
        src={bannerImage} 
        alt="About AA Applications Banner" 
        style={{ width: '100%', height: 'auto' }} 
      />
      
      <div className="hero-content-1 hero-content-position" style={{ color: '#fff' }}>
        <h1 style={{color:"#fff",fontSize:"48px"}}>About <span style={{fontFamily: 'Raqupine Regular', }}> AA APPLICATIONS </span></h1>
        {/* Uncomment these if needed */}
        {/* <h1 className="title" data-aos="fade-up" data-aos-delay="200" style={{ color: '#fff' }}>Co Working</h1> */}
        {/* <p data-aos="fade-up" data-aos-delay="300">Quisque at justo sagittis, semper lacus a, iaculis tellus. Fusce tempor, leo vel iaculis aliquet,</p> */}
        {/* <a href="project.html" className="btn btn-primary btn-hover-dark" data-aos="fade-up" data-aos-delay="400">VIEW PROJECT</a> */}
      </div>
    </div>
  );
};

export default WhoweareBanner;
