import React, { useEffect } from 'react';
import WhatwedoBanner from './WhatwedoBanner'
import WhatwedoBrief from './WhatwedoBrief'
import ServicesCards from './ServicesCards';
// import Packages from './Packages'

const WhatWeDo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures it only runs once when the component mounts

  return (
    <div>
        <WhatwedoBanner/>
        <WhatwedoBrief/>
        {/* <Packages/> */}
        <ServicesCards/>
    </div>
  )
}

export default WhatWeDo