import React from 'react';
import './ServicesCards.css';
import webapp from '../img/aaaservices/webapp.jpeg';
import mobapp from '../img/aaaservices/mobileapps.jpg';
import job from '../img/aaaservices/job.jpeg';
import resourse from '../img/aaaservices/resourse.jpeg'
import { Link } from 'react-router-dom';
const ServicesCards = () => {
  return (
    <div className="" style={{ padding: '20px' }}>
      <div className="container-fluid">
        <div className="container">
        <div className="subheading">
                <h1 style={{ marginBottom: '15px', textAlign: 'center',fontSize:"48px" }}>
                SERVICES
                </h1>
              </div>
          {/* First Row */}
          <div className="row">
            <div className="col-12 col-sm-6  col-lg-3">
              <div className="card">
                <img src={webapp} alt="Web Design" />
                <div className="info"><Link to='/web-applications'>
                  <h1 style={{color: "#7cd1e6"}}>Web Design</h1>
                  <p style={{color:"#fff"}}>
                  "Create beautiful, responsive websites that enhance your brand and engage your audience."</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6  col-lg-3">
              <div className="card">
                <img src={mobapp} alt="Apps" />
                <div className="info">
                  <Link to='/mobile-application'>
                  <h1 style={{color: "#7cd1e6"}}>Mobile Apps</h1>
                  <p style={{color:"#fff"}}>"Build intuitive, high-performance apps that deliver seamless user experiences across all platforms."</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6  col-lg-3">
              <div className="card">
                <img src={job} alt="Jobs" />
                <div className="info">
                  <Link to='/jobs'>
                  <h1 style={{color: "#7cd1e6"}}>Jobs</h1>
                  <p style={{color:"#fff"}}>"Connect with top career opportunities and unlock your potential with our expert job placement services."</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6  col-lg-3">
              <div className="card">
                <img src={resourse} alt="Resources" />
                <div className="info">
                  <Link to='/human-resource'>
                  <h1 style={{color: "#7cd1e6"}}>Resources</h1>
                  <p style={{color:"#fff"}}>"Access a wealth of resources to enhance your skills, knowledge, and career growth."</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesCards;
