import React from 'react';


const OurCommitments = () => {
  return (
    <section id="commitment" style={{ paddingTop: '40px' }}>
      <div className="container">
        <div className="row">
          <section id="cards-containers">
            <div className="container">
              <div className="section-title fl-wrap" style={{ textAlign: 'center' }}>
                <h2 style={{ marginBottom: '5px',fontSize:"48px",letterSpacing: '0.025em' }}>Our Commitments</h2>
              </div>
            </div>

            {/* Our Vision Card */}
            <div className="cards-wrappers">
              <div className="cards">
                <div className="card-tops gradient-green">
                  <div className="icon">
                    <i className="fa fa-crosshairs" style={{ color: '#363435' }}></i>
                  </div>
                </div>
                <div className="card-content">
                  <h4 className="card-title" style={{ paddingTop: '40px' }}>Our Vision</h4>
                </div>
                <div className="overlay zoom">
                  <h4 style={{ color: 'rgb(124 209 230)' }}>Our Vision</h4>
                  <p style={{ color: 'white', fontSize: '13px' }}>
                  To be a trusted ally for organizations in navigating technological challenges, ensuring they derive maximum value from their investments in Information Technology.
                    </p>
                </div>
              </div>
            </div>

            {/* Our Mission Card */}
            <div className="cards-wrappers">
              <div className="cards">
                <div className="card-tops gradient-green">
                  <div className="icon">
                    <i className="fa fa-bullseye" style={{ color: '#363435' }}></i>
                  </div>
                </div>
                <div className="card-content">
                  <h4 className="card-title" style={{ paddingTop: '40px' }}>Our Mission</h4>
                </div>
                <div className="overlay zoom">
                  <h4 style={{ color: 'rgb(124 209 230)' }}>Our Mission</h4>
                  <p style={{ color: 'white', fontSize: '12px' }}>
                  To partner with our clients to enhance their competitive advantage through efficient and reliable IT solutions, utilizing the latest tools and technologies. </p>
                </div>
              </div>
            </div>

            {/* Our Values Card */}
            <div className="cards-wrappers">
              <div className="cards">
                <div className="card-tops gradient-green">
                  <div className="icon">
                    <i className="fa fa-heart" style={{ color: '#363435' }}></i>
                  </div>
                </div>
                <div className="card-content">
                  <h4 className="card-title" style={{ paddingTop: '40px' }}>Our Values</h4>
                </div>
                <div className="overlay zoom">
                  <h4 style={{ color: 'rgb(124 209 230)' }}>Our Values</h4>
                  <p style={{ color: 'white', fontSize: '14px',  }}>
                 <b>Customer Focus:</b>  Prioritizing client needs and satisfaction.<br />
                 <b>Teamwork:</b> Encouraging collaboration to achieve goals.<br />
                 <b>Excellence:</b> Committing to high-quality solutions.<br />
                 <b>Integrity:</b> Maintaining honesty and transparency.<br />
                  {/* Well-being: Supporting a positive and healthy work environment.<br /> */}
                   
                   
                  </p>
                </div>
              </div>
            </div>

          </section>
        </div>
      </div>
    </section>
  );
};

export default OurCommitments;
