import React from 'react';
import CountUp from 'react-countup';
import interiorDesignIcon from '../img/icon/interior-designs.png'; // Adjust the path
import recommendIcon from '../img/icon/recommend.png'; // Adjust the path
import experienceIcon from '../img/icon/experience.png'; // Adjust the path

const Stack = () => {
  return (
    <section className="counter-section stack">
      <div className="container">
        {/* <h1 className="main-title">Counter Section</h1> */}
        <div className="row" style={{ marginTop: '-180px' }}>
          {/* <div className="col-lg-4 col-sm-3">
            <div className="counter-count">
              <img src={interiorDesignIcon} alt="Branches Icon" height="50px" />
              <span className="count">
                <CountUp start={0} end={300} duration={2.5} />
              </span>
              <h3>Branches</h3>
            </div>
          </div> */}
          <div className="col-lg-6 col-sm-6">
            <div className="counter-count">
              <img src={recommendIcon} alt="Satisfied Clients Icon" height="50px" />
              <span className="count">
                <CountUp start={0} end={16} duration={2.5} />
              </span>
              <h3>Growing Clients</h3>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6">
            <div className="counter-count">
              <img src={experienceIcon} alt="Experience Icon" height="50px" />
              <span className="count">
                <CountUp start={0} end={6} duration={2.5} />
              </span>
              <h3>Experience</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stack;
