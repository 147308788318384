import React from 'react';
import aboutImg from '../img/aboutus.jpg'; // Adjust the path as per your folder structure

const Aboutus = () => {
  return (
    <section className="about-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-title">
              {/* <h2>
                <span style={{ fontSize: '15px', fontSize: '42px', color: '#363435' }}>ABOUT US</span>
              </h2> */}
              {/* <h2 style={{ color: '#000',fontSize:'48px',fontWeight:'700' }}>
              Welcome to <span  style={{ color: 'rgb(124 209 230)',fontSize:'32px',fontWeight:'700',fontFamily: 'Raqupine Regular' }}>AA Applications</span>
              </h2> */}
              <p style={{ fontSize: '16px', textAlign: 'justify' }}>
              Your Trusted IT Partner for IT Services and Applications Development
              </p>
              <p style={{ fontSize: '16px', textAlign: 'justify' }}>
              "Providing Automated Products for IT Enterprises"
              </p>
              <p></p>
              <p style={{ fontSize: '16px', textAlign: 'justify',}}>
              At <span style={{fontFamily: 'Raqupine Regular',color:'#000' }}> AA APPLICATIONS </span> , We believe that technology should support and enhance your organization’s success, not constrain it. With our wide range of best-in-class services, we provide customized solutions that fit your unique IT needs. We’re committed to excelling at our job so you can focus on doing yours.
              </p>
              <p style={{ fontSize: '16px', textAlign: 'justify' }}>
              In order for enterprises to be fully compliant with regulations, enterprises need to improve business processes, information flow within and across enterprises and provide secure information delivery.
            </p>
            <p style={{ fontSize: '16px', textAlign: 'justify' }}>
            At <span style={{fontFamily: 'Raqupine Regular' ,color:'#000'}}> AA APPLICATIONS </span>, we specialize in developing innovative IT solutions that streamline your processes and empower your organization. Whether it’s automating routine tasks, enhancing operational efficiency, or improving customer engagement, we offer cutting-edge technology solutions that evolve with your business needs. Our dedicated team of experts ensures that every project is executed with precision, resulting in secure, scalable, and high-performance IT products. </p>
           
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-text">
              <img src={aboutImg} alt="About Us" style={{width:"100%", height:"550px"}} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Aboutus;
