import React from 'react';
import Slider from "react-slick";
import iimg2 from '../img/projects/services/itservices.jpg';
import iimg3 from '../img/projects/services/applications.jpg';
import iimg4 from '../img/projects/services/gaming.jpg';
import iimg1 from '../img/projects/services/customer-application-dev.jpg';
// import Commercial7 from '../img/banner/Commercial7.png';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

const Services = () => {
  // Slick slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
     autoplay: true,
      autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  return (
    <section className="services-boxes-area" style={{ backgroundColor: '#e6eeef' }}>
      <div className="">
        <Slider {...settings}>
          <div className="single-box">
            <img src={iimg2} alt="Residential Interiors" />
            <div className="contents">
              <h3>
                <Link to="/it-services">IT Services </Link>
              </h3>
            </div>
            <div className="hover-content">
              <h3>
                <Link to="/it-services">IT Services</Link>
              </h3>
              <p><span style={{fontFamily: 'Raqupine Regular' }}> AA APPLICATIONS </span> helps empower enterprises with business process innovation, enterprise agility, collaboration across the ecosystem and utilization of information as a strategic asset.</p>
              <Link to="/it-services" className="read-more-btn">Learn More</Link>
            </div>
          </div>

          <div className="single-box">
            <img src={iimg3} alt="Commercial Interiors" />
            <div className="contents">
              <h3>
                <Link to="/web-mobile-applications">PC and Mobile Applications</Link>
              </h3>
            </div>
            <div className="hover-content">
              <h3>
                <Link to="/web-mobile-applications">PC and Mobile Applications</Link>
              </h3>
              <p><span style={{fontFamily: 'Raqupine Regular' }}> AA APPLICATIONS </span> helps to build PC Applications and Mobile on demand services. Scalable and extensible architectures that supports 24/7 business availability for global customers as it reduces development cycles and risks to a minimum</p>
              <Link to="/web-mobile-applications" className="read-more-btn">Learn More</Link>
            </div>
          </div>

          <div className="single-box">
            <img src={iimg4} alt="Hospitality Interiors" />
            <div className="contents">
              <h3>
                <Link to="/gaming">Gaming</Link>
              </h3>
            </div>
            <div className="hover-content">
              <h3>
                <Link to="/gaming">Gaming</Link>
              </h3>
              <p>Game development is a hybrid field, drawing from the fields of computer science, mathematics, and production. 

                  ​Game development is the field that spans the entirety of the many aspects involved in creating a video game, from conception and story line to graphic design, programming, and public release</p>
              <Link to="/gaming" className="read-more-btn">Learn More</Link>
            </div>
          </div>

          <div className="single-box">
            <img src={iimg1} alt="Showcase Interiors" />
            <div className="contents">
              <h3>
                <Link to="/custom-applications">Custom Application Development</Link>
              </h3>
            </div>
            <div className="hover-content">
              <h3>
                <Link to="/custom-applications">Custom Application Development</Link>
              </h3>
              <p>Often, the software that suits your specific needs simply isn't available on the market. When packaged solutions and software aren't sufficient or flexible enough for your enterprise, <span style={{fontFamily: 'Raqupine Regular' }}> AA APPLICATIONS </span> can custom-build to your specifications efficiently, and delivering our proven certainty.</p>
              <Link to="/custom-applications" className="read-more-btn">Learn More</Link>
            </div>
          </div>

         
        </Slider>
      </div>
    </section>
  );
};

export default Services;
