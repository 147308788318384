import React from 'react';
import residentialCollage from '../img/whatwedobrief.jpeg'; // Adjust the path as needed

const WhatwedoBrief = () => {
  return (
    <section className="no-top no-bottom bggray" aria-label="abouthome" style={{marginTop:"20px"}}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 p-0">
            <img
              src={residentialCollage}
              className="imgbg-col"
              alt="imghome"
            />
          </div>
          <div className="col-md-6 centered">
            <div className="detailcontent">
              <div className="subheading">
                <h1 style={{ marginBottom: '15px', textAlign: 'center',fontSize:'32px' }}>
                <span style={{fontFamily: 'Raqupine Regular' }}> AA APPLICATIONS </span>
                </h1>
              </div>
              <div className="textdetail">
              IT Services and Applications Development provides a variety of innovative and comprehensive IT solutions. We deliver quality services in the most efficient way, and our experts will work collaboratively with you to customize our offerings to your particular needs. Book a meeting with one of our consultants to hear more about how we can assist your operation.
              </div>
              {/* Optional button or additional content can be added here */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatwedoBrief;
