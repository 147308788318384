import React from 'react';
import retailStore from '../img/banner/whatwedo-banner.jpeg'; // Adjust the path as needed

const WhatwedoBanner = () => {
  return (
    <section
      className="breadcumb"
      aria-label="breadcumb"
      style={{ backgroundImage: `url(${retailStore})`}}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="main">
              <div className="bread">
                <div className="bread-title" style={{fontSize:'48px'}}>What We Do</div>
                <div className="bread-subtitle">
                  {/* Optional breadcrumb links */}
                  {/* <a href="/">Home</a>
                  <span className="spacebread"></span> */}
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatwedoBanner;
