import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Nav from './Component/Nav';
import Footer from './Component/Footer';
import Home from './Component/Home';
import WhoweAre from "./Component/WhoweAre";
import Contactus from "./Component/Contactus";
import WhatWeDo from "./Component/WhatWeDo";
import Gaming from "./Component/Gaming";
import Resources from "./Component/Resources";
import PCMobileApplications from "./Component/PCMobileApplications";
import Itservices from "./Component/Itservices";
import WebApplications from "./Component/WebApplications";
import MobileApp from "./Component/MobileApp";
import Jobs from "./Component/Jobs";
import HumanResource from "./Component/HumanResource";


function App() {
  return (
    <div>
    <Router>
      <Nav />
      <Routes>

        
        <Route path="/" element={<Home />} />
        {/* <Route path="/about-us" element={<AboutUs />}></Route> */}
        <Route path="/who-we-are" element={<WhoweAre />}></Route>
        <Route path="/contact" element={<Contactus />}></Route>
        <Route path="/what-we-do" element={<WhatWeDo />}></Route>
        
        <Route path="/gaming" element={<Gaming />}></Route>
        <Route path="/custom-applications" element={<Resources />}></Route>
        <Route path="/web-mobile-applications" element={<PCMobileApplications />}></Route>
        <Route path="/it-services" element={<Itservices />}></Route>
        <Route path="/web-applications" element={<WebApplications/>}></Route>
        <Route path="/mobile-application" element={<MobileApp/>}></Route>
        <Route path="/jobs" element={<Jobs/>}></Route>
        <Route path="/human-resource" element={<HumanResource/>}></Route>

      </Routes>

      <Footer/>

    </Router>
  </div>
  );
}

export default App;
