import React,{useEffect} from 'react'
import bannerImage from '../img/banner/businessman-application-human-digital-business_1150-1729.jpg';
import abouthome from '../img/services/world-map.jpg'
import ServicesCards from './ServicesCards';
const HumanResource = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []); // Empty dependency array ensures it only runs once when the component mounts
    
  return (
    <div>
    <div>
   <div className="hero-area overly-style-1 opacity-point-4">
 {/* Replace video with image */}
 <img 
   className="banner-image" 
   src={bannerImage} 
   alt="About AA Applications Banner" 
   style={{ width: '100%', height: 'auto' }} 
 />
 
 <div className="hero-content-1 hero-content-position" style={{ color: '#fff' }}>
   <h1 style={{color:"#fff" ,fontSize:'48px'}}>RESOURCES</h1>
   {/* Uncomment these if needed */}
   {/* <h1 className="title" data-aos="fade-up" data-aos-delay="200" style={{ color: '#fff' }}>Co Working</h1> */}
   {/* <p data-aos="fade-up" data-aos-delay="300">Quisque at justo sagittis, semper lacus a, iaculis tellus. Fusce tempor, leo vel iaculis aliquet,</p> */}
   {/* <a href="project.html" className="btn btn-primary btn-hover-dark" data-aos="fade-up" data-aos-delay="400">VIEW PROJECT</a> */}
 </div>
</div>
</div>
   <section className="no-top no-bottom bggray" aria-label="abouthome">
 <div className="container-fluid">
   <div className="row">
     <div className="col-md-6 p-0">
   <img src={abouthome}className="imgbg-col" alt="imghome" />
     </div>
     <div className="col-md-6 centered">
       <div className="detailcontent">
         <div className="subheading" style={{ color: '#1c1d1d', fontSize: '48px', textAlign: 'center', marginBottom: '11px' }}>
           About <span style={{ color: '#1c1d1d', fontSize: '48px' }}>Resources</span>
         </div>
         {/* <div className="heading">Best Interior is Our Passion</div> */}
         <div className="textdetail">
         <p style={{ fontSize: '16px', textAlign: 'justify' }}>
         <span style={{fontFamily: 'Raqupine Regular' }}> AA APPLICATIONS </span> provides organizations of all sizes with the best, most trustworthy IT solutions. In the ever-evolving technology market, we believe that companies need reliable allies who can guide them through the challenges that accompany technological growth. We provide IT Support, Services and development services - to name a few. Contact us today to find out more.
          </p>
          <p style={{ fontSize: '16px', textAlign: 'justify' }}>
          "Providing Automated Products for  Enterprises"
          In order for enterprises to be fully compliant with regulations, enterprises need to improve business processes, information flow within and across enterprises and provide secure information delivery. </p>
          <p style={{ fontSize: '16px', textAlign: 'justify' }}>
          <span style={{fontFamily: 'Raqupine Regular' }}> AA APPLICATIONS </span> provides a customer-focused products specialized in business solutions using advanced technologies.  </p>
         </div>
       </div>
     </div>
   </div>
 </div>
</section>
<ServicesCards/>
</div>
  )
}

export default HumanResource