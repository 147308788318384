import React from 'react';
import abouthomeImg from '../img/servicess.jpg'; // Adjust the path based on your folder structure
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faMobileAlt, faBriefcase, faTools, faFileAlt, faGamepad } from '@fortawesome/free-solid-svg-icons'; // Adjusted icons

const Whychoose = () => {
  return (
    <section className="why-choose-us-area">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5 col-md-6">
            <div className="why-choose-us-image">
              <img src={abouthomeImg} alt="Why Choose Us" />
            </div>
          </div>

          <div className="col-lg-7 col-md-12">
            <div className="why-choose-us-content">
              <div className="contents">
                <h2 style={{     color: "#fff", marginBottom: '45px', textAlign: 'center',fontSize:'48px' }}>
                  An Impressive Portfolio <span style={{ color: '#fff' }}> of Services</span>
                </h2>
                <h4 style={{     color: "#7cd1e6", marginBottom: '45px', textAlign: 'center' }}>Success-Driven Partnerships</h4>
                <p>
                  Together, we don’t just aim for short-term wins—we build a foundation for sustained success, allowing your business to thrive in an ever-evolving digital landscape.
                </p>

                <ul className="features-list">
                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faRocket} />
                    </div>
                    <span>Web Design</span>
                  </li>

                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faMobileAlt} />
                    </div>
                    <span>Apps</span>
                  </li>

                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faBriefcase} />
                    </div>
                    <span>Jobs</span>
                  </li>

                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faTools} />
                    </div>
                    <span>Resources</span>
                  </li>

                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faFileAlt} />
                    </div>
                    <span>Resume Store</span>
                  </li>

                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faGamepad} />
                    </div>
                    <span>Smart Games</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Whychoose;
