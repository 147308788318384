import React from 'react';
import bannerImage from '../img/banner/banner.jpg'; // Adjust the path according to your project structure

const MainBanner = () => {
  return (
    <div className="hero-area overly-style-1 opacity-point-4" style={{ height: '650px', position: 'relative' }}>
      {/* Image that covers the full screen */}
      <img 
        className="banner-image" 
        src={bannerImage} 
        alt="Main Banner" 
        style={{ 
          // position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover' // Ensures the image covers the entire area without distortion
        }} 
      />
 <div className="hero-content-1 hero-content-position" style={{ color: '#fff',  zIndex: 1 }}>
  <h1 style={{ color: "#fff",  fontFamily: "Urbanist, serif", fontWeight: 700,letterSpacing: "4px"   }}>
    Welcome To <span style={{fontFamily: 'Raqupine Regular' }}> AA APPLICATIONS </span>
  </h1>
</div>
    </div>
  );
}

export default MainBanner;
